import React from 'react';

import './global.css';
import Routes from './Routes';


function App() {
  return (
    <Routes/>
  );
};

export default App;
